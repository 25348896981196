import {Translations} from './translations';

export const manageBookingsEvent = 'manageBookings';
export const openBookButtonSettingsEvent = 'openBookButtonSettings';
export const openDailyTimeTableSettingsEvent = 'openDailyTimeTableSettings';
export const openDailyTimeTableSettingsTextTabEvent = 'openDailyTimeTableSettingsTextTab';
export const manageStateEvent = 'manageState';
export const openStaffListSettingsEvent = 'openStaffListSettings';
export const manageStaffEvent = 'manageStaff';

export function generateActions(translation: Translations) {
  return {
    manageBookingsAction : {
      label: translation.t('bookings-pages.actions.manage'),
      actionId: manageBookingsEvent
    },
    manageStateAction : {
      label: translation.t('bookings.daily-timetable.component.label.SwitchState'),
      actionId: manageStateEvent
    },
    bookButtonSettings : {
      label: translation.t('bookings.book-button.component.gfpp.Settings'),
      actionId: openBookButtonSettingsEvent
    },
    openDailyTimeTableSettings : {
      label: translation.t('bookings.daily-timetable.component.gfpp.Settings'),
      actionId: openDailyTimeTableSettingsEvent
    },
    openDailyTimeTableSettingsTextTab : {
      label: translation.t('bookings.daily-timetable.component.gfpp.change-text'),
      actionId: openDailyTimeTableSettingsTextTabEvent
    },
    manageStaffAction : {
      label: translation.t('bookings.staff-list.component.gfpp.manage-staff'),
      actionId: manageStaffEvent
    },
    openStaffListSettings : {
      label: translation.t('bookings.staff-list.component.gfpp.Settings'),
      actionId: openStaffListSettingsEvent
    },
  }
}
